import firebase from 'firebase/app'
import store from '@/store'

// Detail-Editタイプ(Listなし)
export default async function mergeByUID(payload) {
  const { collection, data } = payload
  const uid = store.getters['userBasic/uid']

  try {
    await firebase.firestore().collection(collection).doc(uid).set(data, { merge: true })

    const response = {
      status: 'success',
    }
    return response
  } catch (error) {
    const response = {
      status: 'error',
      msg: error,
    }
    return response
  }
}
